.DatePicker__days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: var(--size--x1);
}

.DatePicker__day {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  transition-property: color;
  transition-duration: var(--transition-time--fast);
  transition-timing-function: var(--transition-timing-function);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale(0);
    opacity: 0;
    border-radius: 50%;
    background-color: var(--color-blue);
    transition-property: transform, opacity, background-color;
    transition-duration: var(--transition-time--fast);
    transition-timing-function: var(--transition-timing-function);
  }
}

.DatePicker__day-inner {
  position: relative;
}

.DatePicker__day--selectable {
  cursor: pointer;
}

.DatePicker__day--unselectable {
  color: var(--color-gray);
}

.DatePicker__day--selected,
.DatePicker__day--selectable.DatePicker__day--unselected:hover {
  border-radius: 50%;
  color: var(--color-blue);

  &::before {
    transform: scale(calc(2.5 / 3));
    opacity: 1;
    background-color: var(--color-blue-light);
  }
}

.DatePicker__day--selected {
  color: var(--color-light);
}

.DatePicker__day--selected::before {
  transform: scale(1);
  background-color: var(--color-blue);
}
