.Visibility {
  opacity: 0;
  transition-property: opacity;
  transition-duration: var(--transition-time--base);
  transition-timing-function: var(--transition-timing-function);
  pointer-events: none;
}

.Visibility__container--enabled:hover .Visibility--uncontrolled,
.Visibility--visible {
  opacity: 1;
  pointer-events: all;
}
