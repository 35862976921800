:root {
  --Checkbox--size: var(--size--x6);
}

.Checkbox__input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.Checkbox__indicator {
  width: var(--Checkbox--size);
  height: var(--Checkbox--size);
  border: 0.0625rem solid var(--color-gray);
  border-radius: var(--size--x1);
  box-shadow: inset 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.1);
  background-color: var(--color-white);
  color: transparent;
  cursor: pointer;
  transition-property: border-color, box-shadow, background-color, color;
  transition-duration: var(--transition-time--base);
  transition-timing-function: var(--transition-timing-function);
}

.Checkbox__input:focus + .Checkbox__indicator {
  border-color: var(--color-blue);
}

.Checkbox__input:checked + .Checkbox__indicator {
  border-color: var(--color-blue);
  background-color: var(--color-blue);
  color: var(--color-light);
}
