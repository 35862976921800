.Card__row {
  transition-property: background-color;
  transition-duration: var(--transition-time--base);
  transition-timing-function: var(--transition-function);

  &:not(:last-child) {
    border-bottom: 0.0625rem solid var(--color-light-gray);
  }
}

.Card__row--clickable {
  &:hover {
    background-color: var(--color-blue-light);
  }
}
