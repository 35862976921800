/* stylelint-disable no-descending-specificity */

.Sidebar {
  position: sticky;
  top: 0;
  max-height: 100vh;
  background-color: var(--color-blue-dark);
  color: var(--color-light);
  overflow: auto;
}

.Sidebar__header,
.Sidebar__menu {
  border-bottom-width: 0.0625rem;
  border-bottom-style: solid;
  border-bottom-color: var(--color-blue);
}

.Sidebar__menu:first-child {
  border-top-width: 0.0625rem;
  border-top-style: solid;
  border-top-color: var(--color-blue);
}

.Sidebar__menu-item {
  position: relative;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--size--x1);
    opacity: 0;
    border-radius: 0 var(--size--x1) var(--size--x1) 0;
    background-color: var(--color-light);
    transition-property: opacity;
    transition-duration: var(--transition-time--slow);
    transition-timing-function: var(--transition-timing-function);
  }
}

.Sidebar__menu-item-link {
  position: relative;
  padding: var(--size--x4) var(--size--x6);
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--size--x2);
    z-index: -1;
    transition-property: transform, border-radius, background-color;
    transition-duration: var(--transition-time--base);
    transition-timing-function: var(--transition-timing-function);
  }
}

.Sidebar__menu-item--active,
.Sidebar__menu-item--active:hover {
  &::before {
    opacity: 1;
  }

  & .Sidebar__menu-item-link::before {
    transform: scale(1.2);
    border-radius: 0;
  }
}

.Sidebar__sub-menu-item {
  padding: var(--size--x2) var(--size--x6);
  border-top-width: 0.0625rem;
  border-top-style: solid;
  border-top-color: var(--color-blue-dark);
  cursor: pointer;
  transition-property: background-color;
  transition-duration: var(--transition-time--base);
  transition-timing-function: var(--transition-timing-function);
}

.Sidebar__sub-menu-item,
.Sidebar__menu-item--active .Sidebar__menu-item-link::before,
.Sidebar__menu-item:hover .Sidebar__menu-item-link::before {
  background-color: rgba(var(--color-light-rgb), 0.05);
}

.Sidebar__sub-menu-item--active,
.Sidebar__sub-menu-item:hover {
  background-color: rgba(var(--color-light-rgb), 0.1);
}
