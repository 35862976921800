.Tabs__tab {
  position: relative;
  padding-top: var(--size--x3);
  padding-bottom: var(--size--x3);
  color: var(--color-dark-gray);
  cursor: pointer;
}

.Tabs__tab::before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0.1875rem;
  border-top-left-radius: var(--size--x1);
  border-top-right-radius: var(--size--x1);
  background-color: transparent;
  transition-property: background-color, color;
  transition-duration: var(--transition-duration--fast);
  transition-timing-function: var(--transition-timing-function);
}

.Tabs__tab--active {
  color: var(--color-blue);

  &::before {
    background-color: var(--color-blue);
  }
}
