/* stylelint-disable selector-max-universal, selector-max-id, selector-max-type */

@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Roboto:wght@400;500&display=swap');

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  min-height: 100%;
  box-sizing: border-box;
}

canvas {
  display: block;
}

svg:not(:root) {
  display: block;
  overflow: visible;
}

body {
  color: var(--color-dark);
  font-family: var(--text-family-ui);
  font-size: var(--text-size--x3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: var(--text-letter-spacing--x3);
  line-height: var(--text-height--x3);
}

a {
  color: inherit;
  text-decoration: none;
}
