/* stylelint-disable no-descending-specificity */

.Table {
  width: 100%;
  border-spacing: 0;
  text-align: left;
}

.Table__header-cell {
  padding: var(--size--x4);
  font-weight: var(--text-weight--strong);
  vertical-align: bottom;
}

.Table__header-cell--sortable:hover {
  color: var(--color-blue);
  cursor: pointer;
}

.Table__body {
  border-radius: var(--size--x2);
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.06);
}

.Table__body-row:not(:last-child) .Table__body-cell {
  border-bottom: 0.0625rem solid var(--color-light-gray);
}

.Table__body-cell {
  padding: var(--size--x4);
  background-color: var(--color-light);
  transition-property: background-color, color;
  transition-duration: var(--transition-time--base);
  transition-timing-function: var(--transition-function);
}

.Table__body-cell--shrink {
  width: 0.0625rem;
  white-space: nowrap;
}

.Table__body-row--clickable:hover .Table__body-cell {
  background-color: var(--color-blue-light);
  cursor: pointer;
}

.Table__body-row:first-child .Table__body-cell {
  &:first-child { border-top-left-radius: var(--size--x2); }
  &:last-child { border-top-right-radius: var(--size--x2); }
}

.Table__body-row:last-child .Table__body-cell {
  &:first-child { border-bottom-left-radius: var(--size--x2); }
  &:last-child { border-bottom-right-radius: var(--size--x2); }
}

.Table--type-card .Table__header-cell,
.Table--type-card .Table__cell {
  padding: var(--size--x4);
}
