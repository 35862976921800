/* body {
  font-family: var(--text-family--content);
  font-size: var(--text-size--body);
  -webkit-font-smoothing: antialiased;
  line-height: var(--text-height--body);
} */

.Text {
  font-weight: inherit;
}

.Text--align-start  { text-align: left; }
.Text--align-middle { text-align: center; }
.Text--align-end    { text-align: right; }

.Text--break-all  { word-break: break-all; }
.Text--break-none { white-space: nowrap; }
.Text--break-word { word-wrap: break-word; }

.Text--family-Article { font-family: var(--text-family-article); }
.Text--family-UI { font-family: var(--text-family-ui); }

.Text--size-x1 {
  font-size: var(--text-size--x1);
  letter-spacing: var(--text-letter-spacing--x1);
  line-height: var(--text-height--x1);
}

.Text--size-x2 {
  font-size: var(--text-size--x2);
  letter-spacing: var(--text-letter-spacing--x2);
  line-height: var(--text-height--x2);
}

.Text--size-x3 {
  font-size: var(--text-size--x3);
  letter-spacing: var(--text-letter-spacing--x3);
  line-height: var(--text-height--x3);
}

.Text--size-x4 {
  font-size: var(--text-size--x4);
  letter-spacing: var(--text-letter-spacing--x4);
  line-height: var(--text-height--x4);
}

.Text--size-x5 {
  font-size: var(--text-size--x5);
  letter-spacing: var(--text-letter-spacing--x5);
  line-height: var(--text-height--x5);
}

.Text--strong { font-weight: bold; }
.Text--weak   { font-weight: normal; }

.Text--ellipsis {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Text--emphasis { font-style: italic; }

.Text--titlecase { text-transform: capitalize; }
.Text--uppercase { text-transform: uppercase; }
