.ProgressBar__progress {
  transition-property: left, width;
  transition-duration: var(--transition-time--slow);
  transition-timing-function: var(--transition-timing-function);
}

.ProgressBar__progress-value {
  transform: translate(-50%);
  transition-property: left;
  transition-duration: var(--transition-time--slow);
  transition-timing-function: var(--transition-timing-function);
}
