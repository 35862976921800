:root {
  --Place-arrow-size: var(--size--x3);
}

.Place,
.Place:first-child,
.Place:last-child {
  position: absolute;
  /* margin: var(--Place-arrow-size); */
}

.Place__arrow {
  position: absolute;
  width: var(--Place-arrow-size);
  height: var(--Place-arrow-size);
  pointer-events: none;
}

.Place__arrow,
.Place__arrow:first-child,
.Place__arrow:last-child {
  margin: var(--Place-arrow-size);
}

.Place--top .Place__arrow {
  top: 100%;
  transform:
    translateY(calc(-50% + var(--Place-arrow-size) * -1))
    rotate(45deg);
}

.Place--right .Place__arrow {
  right: 100%;
  transform:
    translateX(calc(50% - var(--Place-arrow-size) * -1))
    rotate(45deg);
}

.Place--bottom .Place__arrow {
  bottom: 100%;
  transform:
    translateY(calc(50% - var(--Place-arrow-size) * -1))
    rotate(45deg);
}

.Place--left .Place__arrow {
  left: 100%;
  transform:
    translateX(calc(-50% + var(--Place-arrow-size) * -1))
    rotate(45deg);
}
