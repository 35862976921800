html {
  min-height: 100%;
  box-sizing: border-box;
}

html,
body,
#Root {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 100%;
  background-color: var(--color-light-gray);
}
